<template>
	<div id="root" class="disable_zoom_on_double_click">
		<Header />
		<router-view :key="$route.fullPath"> </router-view>
		<RegistrationModal v-if="registrationModalOpened" />
		<DepositModal v-if="depositModalOpened" />
		<WithdrawalModal v-if="withdrawalModalOpened" />
		<AuthorizationModal v-if="authModalData.ticketId" />
		<InfoNotification />
		<CountdownModal v-if="countdownOpened" :secondsToCount="secondsToCount" :action="reload" />
		<ConfirmationModal v-if="confirmationModalOpened" />
		<div v-if="submitting" class="loader_wrap z_30"><img class="loader" src="/loader.gif" alt="Loading..." /></div>
		<ExpressTicketModal v-if="expressTicketOpened" />
		<OperaterPanelInfoModal v-if="operatorPanelInfoModalOpened" />
		<notifications group="general" position="bottom left" :style="{ margin: '30px 10px' }" />
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Header from './components/Header';
import RegistrationModal from './components/Modals/ibetModals/RegistrationModal';
import DepositModal from './components/Modals/ibetModals/DepositModal';
import WithdrawalModal from './components/Modals/ibetModals/WithdrawalModal';
import AuthorizationModal from './components/Modals/AuthorizationModal';
import CountdownModal from './components/Modals/CountdownModal';
import ConfirmationModal from './components/Modals/ConfirmationModal';
import ExpressTicketModal from './components/Modals/ExpressTicketModal';
import OperaterPanelInfoModal from './components/Modals/OperatorPanelInfoModal';
import { AleaControlShell } from './utils/AleaControlShell';
import InfoNotification from './components/Notifications/InfoNotification';
import { handleKey, combineHandlers, selectDelayed, getDefaultAmount } from './utils';
import { getMessageData } from '../src/utils/messages';
import { joinedRoom } from './utils/loggers';
import { keys } from 'keycodes-map';
import { resolveLanguage } from './utils/language';
import ticketSocket from './api/ticketSocket';
import {fetchCachedData} from '././settings.json'
import {
	ODDS_CHANGE,
	EVENT_CHANGE,
	EVENT_CHANGE_LIVE,
	EVENT_CHANGE_PREMATCH,
	OFFER_PLAN_UPDATE,
	BONUS_ODDS_CHANGE,
	EVENT_RESULT,
} from './consts/messages';
import {
	resultChangeBufferStateHandler,
	oddsChangeBufferStateHandler,
	eventChangeBufferStateHandler,
	eventChangeLiveBufferStateHandler,
	eventChangePrematchBufferStateHandler,
} from './store/bufferState';

export default {
	components: {
		Header,
		RegistrationModal,
		DepositModal,
		WithdrawalModal,
		AuthorizationModal,
		InfoNotification,
		CountdownModal,
		ConfirmationModal,
		ExpressTicketModal,
		OperaterPanelInfoModal,
	},
	data() {
		return {
			initialized: false,
			ticket: null,
			// countdownOpened: false,
			// secondsToCount: 10,
		};
	},
	computed: mapGetters([
		'registrationModalOpened',
		'depositModalOpened',
		'withdrawalModalOpened',
		'confirmationModalOpened',
		'expressTicketOpened',
		'operatorPanelInfoModalOpened',
		'activeModal',
		'sportsData',
		'authModalData',
		'options',
		'lastSelectedId',
		'systems',
		'combinations',
		'amount',
		'submitting',
		'tempCashoutTicket',
		'cashoutTickets',
		'countdownOpened',
		'secondsToCount',
	]),
	watch: {
		sportsData(sports) {
			// Add socket listeners when initial data is fetched
			if (sports && !this.initialized) {
				sports.forEach((sport) => {
					const room = `alea.${sport.id}`;
					this.$join(room, (rooms) => joinedRoom(room, rooms));
				});
				this.initialized = true;
			}
		},
		systems(newSystems) {
			if (window.rules && !this.options.savePayin) {
				if (
					newSystems.length === 1 &&
					newSystems[0].events &&
					newSystems[0].events.length === 1 &&
					newSystems[0].events[0].isNew
				) {
					return this.updateTicketAmount(getDefaultAmount());
				}
				const live = newSystems.some(({ events }) => events.some(({ live }) => live)) ? 'LIVE_' : '';
				const ticketType =
					this.combinations > 1
						? 'SYSTEM_'
						: newSystems
								.map((system) => ({ ...system, events: system.events.filter((event) => !event.isNew) }))
								.some(({ events }) => events.length > 1)
						? 'MULTI_'
						: 'SINGLE_';
				const rule = `MIN_${live}DEPOSIT_FOR_${ticketType}TICKET`;
				if (window.rules[rule] && this.amount < window.rules[rule]) this.updateTicketAmount(window.rules[rule]);
			}
		},
		submitting(s) {
			let elems = document.getElementById('root').getElementsByTagName('input');
			for (let i = 0; i < elems.length; i++) {
				elems[i].disabled = s;
			}
		},
	},
	methods: {
		...mapActions([
			'initData',
			'initCachedData',
			'updateEvent',
			'updateBonusOdds',
			'addNewPrematchEvent',
			'addNewLiveEvent',
			'updateTicketData',
			'ticketToRisk',
			'cashoutPaidOut',
			'cashoutAccepted',
			'cashoutDenied',
			'cashoutInvalid',
			'ticketToCashoutRisk',
			'storeMessage',
			'startProcessing',
			'stopProcessing',
			'checkInactiveEvents',
			'stopCheckingInactiveEvents',
			'setUserInfoAction',
		]),
		...mapMutations([
			'clearSystems',
			'addNewEmptySystem',
			'clearAddedEvents',
			'updateTicketAmount',
			'disableScan',
			'toggleExpressTicketModal',
			'startCountdown',
			'setTimerSeconds',
		]),

		/**
		 * @param {string} barcode
		 * @param {object} userData
		 * @param {string} userData.firstName
		 * @param {string} userData.lastName
		 * @param {string} userData.jmbg
		 */
		scanBarcode(barcode, userData) {
			// check if barcode is from bonus voucher
			if (barcode.length === 13 && barcode.match(/BV[A-Z0-9]{6}\+[0-9]{4}/)) return;

			this.disableScan();

			this.setUserInfoAction({ userInfo: userData });

			if (userData?.jmbg) {
				this.$router.push({
					path: 'tickets',
					query: {
						barcode,
						timestamp: Date.now(),
						firstName: userData.firstName,
						lastName: userData.lastName,
						jmbg: userData.jmbg,
					},
				});
			} else {
				this.$router.push({ path: 'tickets', query: { barcode, timestamp: Date.now() } });
			}
		},
		updater(data) {
			this.updateTicketData([data, this.$notifications.info, ticketSocket.leave]);
		},

		cashoutPaidOutHandler(data) {
			this.cashoutPaidOut([data, this.$notifications.info]);
		},

		cashoutAcceptedHandler(data) {
			this.cashoutAccepted([data, this.$notifications.info, ticketSocket.leave]);
		},

		cashoutDeniedHandler(data) {
			this.cashoutDenied([data, this.$notifications.info, ticketSocket.leave]);
		},

		cashoutInvalidHandler(data) {
			this.cashoutInvalid([data, this.$notifications.info, ticketSocket.leave]);
		},

		redirectToMain() {
			const { options, clearSystems, clearAddedEvents, addNewEmptySystem, updateTicketAmount } = this;
			if (this.activeModal) return;

			if (this.$router.currentRoute.path !== '/') {
				this.$router.push('/');
				return selectDelayed(this.lastSelectedId, 150);
			}

			clearSystems();
			clearAddedEvents();
			addNewEmptySystem();

			if (!options.savePayin) updateTicketAmount(getDefaultAmount());
		},
		redirectToTickets() {
			if (this.activeModal || window.location.hash === '#/tickets') return;
			this.$router.push('/tickets');
		},
		redirectToBalance() {
			this.$router.push('/balance');
		},
		prevent(e) {
			e.preventDefault();
		},
		reload() {
			window.location.reload();
		},
		// startCountdown() {
		// 	this.countdownOpened = true;
		// },
		changeLanguage(lang) {
			const language = resolveLanguage(lang, 'en');
			if (language !== this.$i18n.locale) {
				localStorage.setItem('lang', language);
				this.$i18n.locale = language;
				this.setTimerSeconds(3);
				this.startCountdown();
				this.$api.changeLanguage(language);
			}
			localStorage.setItem('language', language);
		},
		toggleExpress() {
			this.toggleExpressTicketModal();
		},
	},
	mounted() {
		fetchCachedData ? this.initCachedData() : this.initData();
		window.AleaControlShell.setBarcodeScannerAcceptorEvent(this.scanBarcode);
		window.AleaControlShell.setChangeLanguageEvent(this.changeLanguage);
		const { options, updateTicketAmount } = this;
		if (!options.savePayin) updateTicketAmount(getDefaultAmount());
	},
	created() {
		window.AleaControlShell = AleaControlShell;
		this.$join('alea.offer_plan');
		this.removeListeners = combineHandlers(
			ticketSocket.subscribeTo(
				['invalid', 'refunded', 'placed', 'edited_ticket', 'deleted', 'withdrawn', 'expired'],
				this.updater
			),
			ticketSocket.subscribeTo({ ticket: this.ticketToRisk }),

			ticketSocket.subscribeTo({
				cashout_paid_out: this.cashoutPaidOutHandler,
				cashout_sent: this.ticketToCashoutRisk,
				cashout_accepted: this.cashoutAcceptedHandler,
				cashout_denied: this.cashoutDeniedHandler,
				cashout_invalid: this.cashoutInvalidHandler,
			}),

			this.$subscribeTo({
				[EVENT_RESULT]: (message) => resultChangeBufferStateHandler(EVENT_RESULT, message),
				[ODDS_CHANGE]: (message) => oddsChangeBufferStateHandler(ODDS_CHANGE, message),
				[EVENT_CHANGE]: (message) => eventChangeBufferStateHandler(EVENT_CHANGE, message), // this.updateEvent,
				[EVENT_CHANGE_LIVE]: (message) => eventChangeLiveBufferStateHandler(EVENT_CHANGE_LIVE, message), // this.addNewLiveEvent,
				[EVENT_CHANGE_PREMATCH]: (message) => eventChangePrematchBufferStateHandler(EVENT_CHANGE_PREMATCH, message), // this.addNewPrematchEvent,
				[OFFER_PLAN_UPDATE]: (message) => this.startCountdown(), // this.startCountdown,
				[BONUS_ODDS_CHANGE]: (message) => this.updateBonusOdds(getMessageData(BONUS_ODDS_CHANGE, message)), // this.updateBonusOdds,
			}),
			handleKey('keydown', keys.f1, this.prevent),
			handleKey('keyup', keys.f1, this.redirectToMain),
			handleKey('keyup', keys.f2, this.redirectToTickets),
			handleKey('keyup', keys.f4, this.redirectToBalance),
			handleKey('keydown', keys.escape, this.redirectToTickets),
			handleKey('keydown', keys.backquote, this.toggleExpress)
		);
		this.startProcessing();
		this.checkInactiveEvents();
	},
	destroyed() {
		this.removeListeners && this.removeListeners();
		this.stopProcessing();
		this.stopCheckingInactiveEvents();
	},
};
</script>

<style scoped>
.disable_zoom_on_double_click {
	touch-action: manipulation;
}
.loader_wrap {
	background-color: white;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	opacity: 0.5;
}
.loader {
	background-color: white;
}
</style>
